import { useLocation } from "react-router-dom";
import "./banklisting.scss";

const MoneyViewPage = () => {
    const { state: moneyViewURL } = useLocation();

    return (
        <>
            <div>
                <iframe src={moneyViewURL?.url} width="100%" height="100%"
                    className="moneyview-login"
                    title="Moneyview-Page" />
            </div>
        </>
    )
}

export default MoneyViewPage;