
import IDFCicon from "./IDFCicon.svg";
import casheLogo from "./casheLogo.svg";
import LTlogo from "./logoLT.svg";
import TVS from "./Tvs.svg";
import TataCapitalLogo from "./tataCapitalLogo.svg"
import moneyViewLogo from "./moneyViewLogo.png"

const LogoMapper:any = {
    "LT":LTlogo,
    "CASHE":casheLogo,
    "IDFC First Bank":IDFCicon,
    "TVS":TVS,
    "TATA CAPITAL":TataCapitalLogo,
    "MONEY VIEW" : moneyViewLogo 
}


export default LogoMapper