import { gql } from '@apollo/client';


export const ApplyMoneyView = gql`
  mutation ApplyMoneyViewLoan($input: ApplyMoneyViewLoanInput) {
    applyMoneyViewLoan(input: $input) {
      applyMoneyViewLoanErrors {
        message
      }
      message
      status
      data
    }
}`