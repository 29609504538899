import CashePage from "../pages/BankListingComponent/CashePage";
import MoneyViewPage from "../pages/BankListingComponent/MoneyViewPage";
import LoanComponent from "../pages/LoanComponent/LoanComponent";
import LoanHistory from "../pages/LoanComponent/LoanHistory";
import LoanStatus from "../pages/LoanStatus/LoanStatus";
import BasicDetailsView from "../pages/ProfilePage/BasicDetailsView";
import EmploymentDetailsView from "../pages/ProfilePage/EmploymentDetailsView";
import LocationDetailsView from "../pages/ProfilePage/LocationDetailsView";
import ProfilePage from "../pages/ProfilePage/ProfilePage";
import SupportPage from "../pages/ProfilePage/SupportPage";
import Tools from "../pages/Tools/Tools";
import BasicDetails from "../pages/UserDetails/BasicDetails";
import EmploymentDetails from "../pages/UserDetails/EmploymentDetails";
import LocationDetails from "../pages/UserDetails/LocationDetails";
import PermanentLocationDetails from "../pages/UserDetails/PermanentLocationDetails";
import ViewDetailsPage from "../pages/ViewDetails/ViewDetailsPage";







interface MetaConfig {
    title: string;
    description: string;
    keywords?: string;
    ogTitle?: string;
    ogDescription?: string;
    ogImage?: string;
    canonical?: string;
    noindex?: boolean;
}




interface RouteConfig {
    path: string;
    element: React.ReactElement;
    meta: MetaConfig;
}
// LoanStatus
const privateRoutes: RouteConfig[] = [
    {
        path: '/basic-details', element: <BasicDetails />,
        meta: {
            title: 'Basic Details',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: '/loans', element: <LoanComponent />,
        meta: {
            title: 'Loans',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: '/loan-status', element: <LoanStatus />,
        meta: {
            title: 'Loans Status',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: '/location-details/current-address', element: <LocationDetails />,
        meta: {
            title: 'Current Location Details',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: '/location-details/permanent-address', element: <PermanentLocationDetails />,
        meta: {
            title: 'Permanent Location Details',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: '/employment-details', element: <EmploymentDetails />,
        meta: {
            title: 'Employment Details',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: '/profile', element: <ProfilePage />,
        meta: {
            title: 'Profile',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: '/profile/basic-details', element: <BasicDetailsView />,
        meta: {
            title: 'Basic Details',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: '/profile/location-details', element: <LocationDetailsView />,
        meta: {
            title: 'Location Details',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: '/profile/employment-details', element: <EmploymentDetailsView />,
        meta: {
            title: 'Employment Details',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: '/loan-details-view', element: <ViewDetailsPage />,
        meta: {
            title: 'Loan Details View',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: '/cashe', element: <CashePage />,
        meta: {
            title: 'Cashe',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: '/moneyview', element: <MoneyViewPage />,
        meta: {
            title: 'Moneyview',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: '/profile/support', element: <SupportPage />,
        meta: {
            title: 'Support',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: '/tools', element: <Tools />,
        meta: {
            title: 'Tools',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: '/loan-history', element: <LoanHistory />,
        meta: {
            title: 'Loan History',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },
    {
        path: '/support', element: <SupportPage />,
        meta: {
            title: 'Support',
            description: 'Find the perfect loan solution for your needs',
            keywords: 'home loans, personal loans, quick loans',
            ogTitle: 'Your Finance App - Find Your Perfect Loan',
            ogDescription: 'Easy loan applications with competitive rates',
        }
    },

];


export default privateRoutes