import { Avatar } from "@mui/material";
import AppButton from "../../components/AppButton";
import "./banklisting.scss";
import IDFCicon from "../../assets/IDFCicon.svg";
import ProcessingFee from "../../components/ProcessingFee";
import casheLogo from "../../assets/casheLogo.svg";
import LTlogo from "../../assets/logoLT.svg";
import LogoMapper from "../../assets/Vendors";

interface Props {
    bankListingData: any,
    viewDetails: (data: any) => void,
    proceedToLoan: (data: any) => void,
    source:string
}

const paddingStyle = {
    padding:"10px"
}

const CardView = ({ bankListingData, viewDetails, proceedToLoan,source }: Props) => {

    return (
        <>
            {(bankListingData || []).map((row: any) => (
                <div className="bank-detials-card-view">
                    <span className="d-flex">
                        {<Avatar variant="square"
                            src={LogoMapper[row?.lenderName]}
                            sx={{ width: '24px', height: '24px', bgcolor: 'white', borderRadius: '4px' }} />
                        }
                        <div>&nbsp;{row?.lenderTitle}</div>
                    </span>
                 
                    <div className="bank-listing-details-view mt-15">
                        <div className="d-flex jc-sb" style={paddingStyle}>
                            <div>Interest rate</div>
                            <div className="text-end fw-bold">{row?.interestRate}</div>
                        </div>
                        <div className="d-flex jc-sb" style={paddingStyle}>
                            <div>Processing fee</div>
                            <ProcessingFee actualPercentage={`${row?.processingFee}`}  discountPercentage={row?.discountedProcessingFee} />
                            {/* <div className="text-end fw-bold">{row?.processingFee}</div> */}
                        </div>
                        <div className="d-flex jc-sb" style={paddingStyle}>
                            <div>Approved Amount</div>
                            <div className="text-end fw-bold">{row?.maxLoanEligibilityAmount}</div>
                        </div>
                        <div className="d-flex jc-sb" style={paddingStyle}>
                            <div>Monthly EMI</div>
                            <div className="text-end fw-bold">{row?.monthlyEmi}</div>
                        </div>
                        <div className="d-flex jc-sb" style={paddingStyle}>
                            <div>Prepayment Charges</div>
                            <div className="text-end fw-bold">{source === "revolving credit" ? row?.prePaymentCharges : 
                            row?.lenderName === "CASHE" ? "Zero Charges" :`8%`}</div>
                        </div>
                    </div>
                    <div className="d-flex mt-15">
                        <AppButton text="View details" className="w-100 search-btn" onClick={() => viewDetails(row)} />
                        <AppButton text={ row?.isApplied ?  "View Status" : "Apply now"} className="w-100 app-btn space-pre ml-15"
                            onClick={() => proceedToLoan(row)} />
                    </div>
                </div>
            ))}
        </>
    )
}

export default CardView;