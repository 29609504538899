import Navbar from "../../components/Navbar/Navbar";
import "./viewdetails.scss";
import documentIcon from "../../assets/loanDocumentIcon.svg";
import greytickIcon from "../../assets/greytickIcon.svg";
import thumbsIcon from "../../assets/thumbsIcon.svg";
import AppButton from "../../components/AppButton";
import { useLocation, useNavigate } from "react-router-dom";
import leftArrow from "../../assets/leftArrow.svg";
import { useEffect, useState } from "react";
import { VIEWBANKDETAILS } from "../../query/BankListingDetails/ViewDetailsList";
import { useMutation, useQuery } from "@apollo/client";
import AppModal from "../../components/AppModal/AppModal";
import { ApplyLTLOAN } from "../../query/ProceedLoan/ApplyLoanL&T";
import { CASHELOAN } from "../../query/ProceedLoan/ApplyLoanCashe";
import Snackbar from '@mui/material/Snackbar';
import LoadingModalView from "../../components/LoadingModal";
import { IDFC_FIRST_LOAN } from "../../query/ProceedLoan/ApplyLoanIDFC";
import firstMoneyBanner from "../../assets/FIRSTmoneybanner.svg"
import { Avatar, Box, Button, Typography } from "@mui/material";


import { ApplyTVSLoan } from "../../query/ProceedLoan/ApplyTVSLoan";
import LogoMapper from "../../assets/Vendors";
import { ApplyTataCapital } from "../../query/ProceedLoan/ApplyTataCapital";
import { ApplyMoneyView } from "../../query/ProceedLoan/ApplyMoneyView";


import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { APPLIEDLOANSTATUS } from "../../query/BankListingDetails/AppliedLoans";

// const LogoMapper:any = {
//     "LT":LTlogo,
//     "CASHE":casheLogo,
//     "IDFC First Bank":IDFCicon
// }



const ViewDetailsPage = () => {
    const { state: loanDetails } = useLocation();
    const [bankDetails, { data: bankDetailsResponse, loading }] = useMutation(VIEWBANKDETAILS);
    const [applyLTLoan, { data: loanLTDetailsResponse, loading: loanLoading }] = useMutation(ApplyLTLOAN);
    const [applyCasheLoan, { data: casheDetailsResponse, loading: casheLoading }] = useMutation(CASHELOAN);
    const [applyIDFCLoan, { data: idfcResponse, loading: idfcLoading }] = useMutation(IDFC_FIRST_LOAN);
    const [applyTVSLoan, { data: tvsResponse, loading: tvsResponseLoading }] = useMutation(ApplyTVSLoan);
    const [applyTATALoan,] = useMutation(ApplyTataCapital)
    const [applyMoneyView, { data: moneyViewResponse, loading: moneyViewResponseLoading }] =useMutation(ApplyMoneyView)


    const [isLoading,setLoading] = useState(false)


    const { data: appliedLoanResponse } = useQuery(APPLIEDLOANSTATUS, { 
        fetchPolicy: "network-only", 
        errorPolicy: "all" 
    });

    const [showWarningDialog, setShowWarningDialog] = useState(false);
    const [pendingLender, setPendingLender] = useState<any>(null);

    const [successModal, setSuccessModal] = useState(false);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);




    useEffect(() => {
        if (loanLTDetailsResponse?.applyLTLoan?.status === 200 && loanLTDetailsResponse?.applyLTLoan?.data?.id !== null) {
            setSuccessModal(true);
            navigate("/loans",{state:{id:"false"}});
        } else if (loanLTDetailsResponse?.applyLTLoan?.status === 103 || loanLTDetailsResponse?.applyLTLoan?.status === 429 ||
            loanLTDetailsResponse?.applyLTLoan?.status === 102 || casheDetailsResponse?.applyCasheLoan?.status === 102) {
            navigate("/loans");
        }
    }, [loanLTDetailsResponse, casheDetailsResponse])

    useEffect(() => {
        if (casheDetailsResponse?.applyCasheLoan?.status === 200 && casheDetailsResponse?.applyCasheLoan?.data?.url !== "") {
            navigate("/cashe", {
                state: {
                    url: casheDetailsResponse?.applyCasheLoan?.data?.URL
                }
            });
        }
    }, [casheDetailsResponse])

    useEffect(() => {
        if (moneyViewResponse?.applyMoneyViewLoan.status === 400) {
            setOpen(true);
        }
    }, [moneyViewResponse?.applyMoneyViewLoan])


    useEffect(() => {

      
        const getBankDetails = () => {
            let detailInput = {
                // lenderId: loanDetails?.id,
                lenderName: loanDetails?.lenderName
            }
            bankDetails({
                variables: {
                    input: detailInput
                }
            })
        }
        getBankDetails();
    }, [loanDetails])



    const getButtonType = () => {

        return  loanDetails?.isApplied ?  "View Status" : "Apply now"

    }

    const successModalView = () => {
        return (
            <AppModal openModal={successModal}>
                <div className="text-center">
                    {/* <img src="" alt="loader" className="loading-icon-gif" /> */}
                    <div className="heading fs-24">Congratulations!</div>
                    <div className="mt-1 fs-14">Your loan is being processed! The amount will be credited to your account shortly.</div>
                </div>
            </AppModal>
        )
    }
    

    const proceedLoan = () => {
        let lenderName = bankDetailsResponse?.particularPlan?.data?.lenderName;


        // const  hasRecentLoanApplication =   appliedLoanResponse.appliedLoans
        // .filter((item:any) => item?.loanAppliedStatusId !== "3" &&  item.loanAppliedStatusId !== "6")
        // .some((loan:any) => {
        //     const thirtyDaysAgo = new Date();
        //     thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        //     const loanDate = new Date(loan.createdAt);
        //     return loanDate > thirtyDaysAgo

        // })

        // if (hasRecentLoanApplication && !showWarningDialog && !loanDetails?.isApplied) {
        //     setShowWarningDialog(true);
        //     setPendingLender(loanDetails);
        //     return;
        // }


        if(loanDetails?.isApplied){
            return navigate('/loan-status',{state:lenderName})
        }
        let loanInput = {
            lenderName: lenderName
        }
        if (lenderName === "LT") {
            applyLTLoan({
                variables: {
                    input: loanInput,
                },
            }).then(response => {
                if (response.data.applyLTLoan.url) {
                    const url = new URL(response.data.applyLTLoan.url, window.location.origin);
                    window.open(url.href, '_blank', 'noopener,noreferrer');
                    navigate('/loans')
                }
            })
        } else if (lenderName === "CASHE") {
            applyCasheLoan({
                variables: {
                    input: loanInput,
                },
            })
        } else if (lenderName === "IDFC First Bank") {
            applyIDFCLoan({
                variables: {
                    input: loanInput,
                }
            }).then(response => {
                if (response.data.applyIDFCLoan.url) {
                    const url = new URL(response.data.applyIDFCLoan.url, window.location.origin);
                    window.open(url.href, '_blank', 'noopener,noreferrer');
                    navigate('/loans')
                }
            })


        }else if (lenderName === "TVS") {
            applyTVSLoan({
                variables:{
                    input: loanInput,
                }
            }).then((response) => {

                if (response.data.applyTVSLoan.url) {
                    const url = new URL(response.data.applyTVSLoan.url, window.location.origin);
                    window.open(url.href, '_blank', 'noopener,noreferrer');
                    navigate('/loans')
                }

            })
        }else if (lenderName === "TATA CAPITAL"){
            setLoading(true)
            applyTATALoan({
                variables:{
                    input: loanInput,
                }
            }).then((response) => {

                if (response.data.applyTataCapitalLoan.url) {
                    setLoading(false)
                    const url = new URL(response.data.applyTataCapitalLoan.url, window.location.origin);

                    window.open(url.href, '_blank', 'noopener,noreferrer');

                   
                    navigate('/loans')
                }

               
            })
        }else if(lenderName === "MONEY VIEW"){
            setLoading(true)
            applyMoneyView({
                variables:{
                    input:loanInput,
                }
            })
            .then((response)=>{
                setLoading(false)
                if (response.data.applyMoneyViewLoan.data) {
                    setLoading(false)
                    const url = new URL(response.data.applyMoneyViewLoan.data, window.location.origin);

                    // Open the absolute URL in a new tab
                    window.open(url.href, '_blank', 'noopener,noreferrer');

                    // Back to loans screen
                    navigate('/loans')
                }
            })
        }
    }

    const handleWarningClose = () => {
        setShowWarningDialog(false);
        setPendingLender(null);
    };

    const getMessage = () => {
        if (loanLTDetailsResponse?.applyLTLoan?.status === 102 || loanLTDetailsResponse?.applyLTLoan?.status === 103 || loanLTDetailsResponse?.applyLTLoan?.status === 429) {
            // console.log("getMessage",loanLTDetailsResponse?.applyLTLoan?.status)
            return loanLTDetailsResponse?.applyLTLoan?.message;
        } else if (casheDetailsResponse?.applyCasheLoan?.status === 102) {
            return null;
        }else if(moneyViewResponse?.applyMoneyViewLoan?.status === 400){
            return moneyViewResponse?.applyMoneyViewLoan?.applyMoneyViewLoanErrors[0].message;
        }
        return null;
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    return (
        <>
            <Navbar />
            <div className="pd-30">
                <div className="view-details-card">
                    <div className="d-flex">
                        <img src={leftArrow} alt="leftArrow" onClick={() => navigate(-1)} className="cursor-pointer" />
                        <span className="ml-2" style={{fontSize:"15px",display:"flex",justifyContent:"center",alignItems:"center",gap:"0.5em"}}>
                            {bankDetailsResponse?.particularPlan?.data?.lenderName && <Avatar variant="square" src={LogoMapper[bankDetailsResponse?.particularPlan?.data?.lenderName]} alt={bankDetailsResponse?.particularPlan?.data?.lenderName}> {bankDetailsResponse?.particularPlan?.data?.lenderName} </Avatar>} &nbsp;
                            {bankDetailsResponse?.particularPlan?.data?.lenderTitle}
                        </span>
                    </div>
                    {bankDetailsResponse?.particularPlan?.data?.lenderName === "IDFC First Bank" &&
                        <Box component="a" href="https://www.idfcfirstbank.com/personal-banking/loans/personal-loan#firstmoney-overview" target="_blank">
                            <Box component="img" src={firstMoneyBanner} sx={{ maxWidth: "100%", marginTop: "10px" }} />
                        </Box>
                    }
                    <div className="W-100">
                        <div className="d-flex jc-sb  mt-3">
                            <div className="fs-14">Interest Rate</div>
                            <div className="bold-text">{bankDetailsResponse?.particularPlan?.data?.interestRate}</div>
                        </div>
                        <div className="d-flex jc-sb  mt-3">
                            <div className="fs-14">Processing fee</div>
                            <div className="bold-text">{bankDetailsResponse?.particularPlan?.data?.discountedProcessingFee ? bankDetailsResponse?.particularPlan?.data?.discountedProcessingFee : bankDetailsResponse?.particularPlan?.data?.processingFee }</div>
                        </div>
                        <div className="d-flex jc-sb  mt-3">
                            <div className="fs-14">Max Loan Eligibility Amount</div>
                            <div className="bold-text">{bankDetailsResponse?.particularPlan?.data?.maxLoanEligibilityAmount}</div>
                        </div>
                        <div className="d-flex jc-sb  mt-3">
                            <div className="fs-14">Min Loan Eligibility Amount</div>
                            <div className="bold-text">{bankDetailsResponse?.particularPlan?.data?.minLoanEligibilityAmount}</div>
                        </div>
                        {/* <div className="d-flex jc-sb  mt-3">
                            <div className="fs-14">No Of Installments</div>
                            <div className="bold-text">{loanDetails.noOfInstallments}</div>
                        </div> */}
                    </div>
                    <div className="grey-bor mt-3"></div>
                    <div className="mt-3">
                        <div className="d-flex align-center">
                            <img src={documentIcon} alt="documeny-icon" />
                            <div className="ml-1 bold-text">Documents</div>
                        </div>
                        <div className="mt-3">
                            <div className="d-flex align-center">
                                <img src={greytickIcon} alt="tickIcon" />
                                <div className="ml-2 fs-14 ">PAN Card</div>
                            </div>
                            <div className="d-flex align-center mt-3">
                                <img src={greytickIcon} alt="tickIcon" />
                                <div className="ml-2 fs-14 ">Any government address proof</div>
                            </div>
                            <div className="d-flex align-center mt-3">
                                <img src={greytickIcon} alt="tickIcon" />
                                <div className="ml-2 fs-14 ">3 months payslip / last 6 months bank account statement</div>
                            </div>
                        </div>
                    </div>
                    <div className="grey-bor mt-3"></div>
                    <div className="mt-3">
                        <div className="d-flex align-center">
                            <img src={thumbsIcon} alt="documeny-icon" />
                            <div className="ml-1 bold-text">Eligibility Criteria</div>
                        </div>
                        <div className="mt-3">
                            <div className="d-flex align-center">
                                <img src={greytickIcon} alt="tickIcon" />
                                <div className="ml-2 fs-14 ">Age between {bankDetailsResponse?.particularPlan?.data?.minAge} - {bankDetailsResponse?.particularPlan?.data?.maxAge} years</div>
                            </div>
                            <div className="d-flex align-center mt-3">
                                <img src={greytickIcon} alt="tickIcon" />
                                <div className="ml-2 fs-14 ">Resident of India</div>
                            </div>
                            <div className="d-flex align-center mt-3">
                                <img src={greytickIcon} alt="tickIcon" />
                                <div className="ml-2 fs-14 ">Should have monthly source of income</div>
                            </div>
                            {bankDetailsResponse?.particularPlan?.data?.salary &&
                                <div className="d-flex align-center mt-3">
                                    <img src={greytickIcon} alt="tickIcon" />
                                    <div className="ml-2 fs-14 ">Minimum family income ₹ {bankDetailsResponse?.particularPlan?.data?.salary}</div>
                                </div>}
                        </div>
                    </div>
                    <div className="proceed-view mt-3">
                        <AppButton text={getButtonType()} className="app-btn w-100" onClick={() => proceedLoan()} type="button" />
                    </div>
                </div>
            </div>
            {successModalView()}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message={getMessage()}
                key={"bottom center"}

            />
            <LoadingModalView
                isOpen={loading || loanLoading || casheLoading || isLoading}
                lenderName={loanDetails?.lenderName} />

<Dialog
                open={showWarningDialog}
                onClose={handleWarningClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        borderRadius: '12px',
                        padding: '16px',
                        maxWidth: '450px'
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    padding: '16px 24px'
                }}>
                    <WarningAmberIcon sx={{ color: 'warning.main' }} />
                    <Typography variant="h6">Warning</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{ color: 'text.primary' }}>
                        Please note that making multiple loan applications in a short period of time can impact your credit score. Would you like to proceed with this application?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ padding: '16px 24px' }}>
                    <Button
                        onClick={handleWarningClose}
                        variant="outlined"
                        sx={{
                            minWidth: '100px',
                            borderRadius: '8px'
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => proceedLoan()}
                        variant="contained"
                        sx={{
                            minWidth: '100px',
                            borderRadius: '8px'
                        }}
                    >
                        Proceed Anyway
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ViewDetailsPage;
