import OffersIcon from "../../assets/offers.svg";
import RelationshipIcon from "../../assets/relationshipIcon.svg";
import SaveBigIcon from "../../assets/saveBigIcon.svg";
import loanIcon from "../../assets/loanIcon.svg";
import collateralIcon from "../../assets/collateralIcon.svg";
import documentationIcon from "../../assets/documentationIcon.svg";
import disbursalIcon from "../../assets/disbursalIcon.svg";
import checkedIcon from "../../assets/checkedIcon.svg";
import phoneIcon from "../../assets/phoneIcon.svg";
import bannerImg from "../../assets/bannerImg.svg";
import utubeIcon from "../../assets/utubeIcon.svg";
import instaIcon from "../../assets/instaIcon.svg";
import facebookIcon from "../../assets/facebookIcon.svg";
import rightArrowIcon from "../../assets/rightArrowIcon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import doubleQuotes from "../../assets/doubleQuotes.svg";
import creditReportIcon from "../../assets/creditReportIcon.svg";
import exclusiveOffers from "../../assets/exclusiveOffersIcon.svg";
import menubar from "../../assets/menubar.svg";
import "./index.scss";
import { useEffect, useState } from "react";
import relationshipManagerIcon from "../../assets/relationshipManagerIcon.svg";
import { ValidationErrorMessage } from "../../ValidationError";
import { validateMobileNumber } from "../../utils";
import {
  Button,
  Accordion,
  Container,
  AccordionSummary,
  AccordionDetails,
  Grid,
  FormControl,
  TextField,
  MobileStepper,
  Box,
  Typography,
  useMediaQuery,
  CardContent,
  FormHelperText,
} from "@mui/material";
import AppButton from "../../components/AppButton";
import downArrow from "../../assets/downArrow.svg";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import EmiCardView from "../../components/EmiCardView";
// import LogoIcon from "../../assets/logo.png";
import Creditscore from "../../assets/Creditscore.svg";
import LogoIcon from "../../components/Logo";
import LTlogo from "../../assets/LTFinannce.svg";
import firstMoneyLogo from "../../assets/first_money.svg";
import tata_capital from "../../assets/tata_capital.svg";
import tvs_logo from "../../assets/tvs_logo.svg";
import bhanixLogo from "../../assets/Bhanix.svg";
import MoneyView_logo from "../../assets/MoneyView_Primary_logo.png";
import giftBox from "../../assets/giftBox.svg";
// import croma from "../../assets/croma.svg";
import croma from "../../assets/cromaUpdate.png";
import fab from "../../assets/fab.svg";
import taj from "../../assets/taj.svg";
import tanishq from "../../assets/tanishq.svg";
import img1 from "../../assets/img1.svg";
import img2 from "../../assets/img2.svg";
import img3 from "../../assets/img3.svg";
import img4 from "../../assets/img4.svg";
import img5 from "../../assets/img5.svg";
import IDFCLogo from "../../assets/idfc_logo.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MobileLogo from "../../components/MobileAppLogo";
import chatIcon from "../../assets/chatIcon.svg";
import ReactGA from "react-ga4";
import env from "../../config/config";
import gaEvents from "../../helpers/googleAnalytics";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const item = [
  {
    id: 1,
    content: "Wedding Loan Fundamentals",
    imgSrc: img1,
    link: "/blog",
  },
  {
    id: 2,
    content: "Pre Wedding Loan Checklist",
    imgSrc: img2,
    link: "/blog",
  },
  {
    id: 3,
    content: "Wedding Loan Process",
    imgSrc: img3,
    link: "/blog",
  },
  {
    id: 4,
    content: "Key Technical Terms for Wedding Loan",
    imgSrc: img4,
    link: "/blog",
  },
  {
    id: 5,
    content: "Types of Wedding Loans",
    imgSrc: img5,
    link: "/blog",
  },
];

const LandingPage = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const navigate = useNavigate();
  const [showToolDropdown, setToolDropdown] = useState(false);
  const [showHelpCentreDropdown, setHelpCentreDropdown] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const medium = useMediaQuery((theme: any) => theme.breakpoints.only("md"));
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.only("sm")
  );
  const date = new Date();

  let location = useLocation();

  const [validation, setValidation] = useState({ isValid: true, message: "" });

  useEffect(() => {

    const params = new URLSearchParams(window.location.search);
    const source = params.get('source');

    if (source){
      sessionStorage.setItem('source',source)
    }

    gaEvents.trackHomepageView();
  }, [location]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    const closeSelector = (e: any): void => {
      const targetElement = e.target as HTMLElement;
      let dropdownBoxClicked = targetElement.closest("#dropdownselect")!;
      if (!dropdownBoxClicked) {
        closeAllDropdown();
      }
    };
    document.addEventListener("mousedown", closeSelector);
  }, []);

  const getCarouselItemsList = () => {
    let carouselTemp = [];
    for (let i = 0; i < item.length; i = i + 3) {
      let newarr = [];
      for (let j = i; j < i + 3; j++) {
        newarr.push({ ...item[j], activeIndex: i + 1 });
      }
      carouselTemp.push(newarr);
    }
    return carouselTemp;
  };

  const openSidebar = () => {
    setShowSidebar(true);
  };

  const closeSideBar = () => {
    setShowSidebar(false);
  };

  const loanBenefitsView = () => {
    try {
      return (
        <section className="container-width pd-30 mt-3">
          <div className="header-text word-break">WeddingLoan.com benefits</div>
          <Grid container className="mt-3" spacing={3}>
            <Grid item lg={3} md={3} sm={6} className="w-100">
              <div className="loan-benefits-card-view paperless-loan-bg">
                <div className="mt-10">
                  <img src={loanIcon} alt="OffersIcon" />
                </div>
                <div className="mt-28 pd-10">
                  Higher loan limits & best interest rates
                </div>
              </div>
            </Grid>
            <Grid item lg={3} md={3} sm={6} className="w-100">
              <div className="loan-benefits-card-view collaterl-free-loan-bg">
                <div className="mt-10">
                  <img src={collateralIcon} alt="RelationshipIcon" />
                </div>
                <div className="mt-28 pd-10">
                  Collateral free loan processing
                </div>
              </div>
            </Grid>
            <Grid item lg={3} md={3} sm={6} className="w-100">
              <div className="loan-benefits-card-view disbursal-loan-bg">
                <div className="mt-10">
                  <img src={disbursalIcon} alt="SaveBigIcon" />
                </div>
                <div className="mt-28 pd-10">
                  Quick Disbursal into your account
                </div>
              </div>
            </Grid>
            <Grid item lg={3} md={3} sm={6} className="w-100">
              <div className="loan-benefits-card-view documentation-bg">
                <div className="mt-10">
                  <img src={documentationIcon} alt="SaveBigIcon" />
                </div>
                <div className="mt-28 pd-10">Minimal documentation</div>
              </div>
            </Grid>
          </Grid>
        </section>
      );
    } catch (e) {
      // console.log("Error in loanBenefitsView::", e);
    }
  };

  const creditsSection = () => {
    try {
      return (
        <section className="container-width pd-30" id="creditScoreSection">
          <Grid container className="jc-sb gy-3">
            <Grid
              item
              order={{ sm: 2, lg: 1, md: 1, xs: 2 }}
              lg={6}
              md={8}
              sm={12}
              xs={12}
            >
              <div className="header-text">
                Get credit score & detailed report for worth{" "}
                <span className="text-decoration-line-through">₹1200</span>{" "}
                <span className="free-text text-uppercase">Free</span>
              </div>
              <div className="mt-3 mb-4">
                Your credit score helps your increase your creditworthiness.
                Improve it now to get best loan offers.
              </div>
              <Button
                className="app-btn"
                onClick={() => navigate("/credit-score")}
              >
                Get free credit report
              </Button>
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={12}
              xs={12}
              order={{ sm: 1, lg: 2, md: 1, xs: 1 }}
            >
              <div className="text-center">
                <img
                  src={Creditscore}
                  alt="circleImg"
                  className="credit-score-img"
                />
                {/* <img src={document1}/>
                                <img src={document2}/> */}
              </div>
            </Grid>
          </Grid>
        </section>
      );
    } catch (e) {
      // console.log("Error in loanBenefitsView::", e);
    }
  };

  const meetmanagerSection = () => {
    return (
      <section className="meet-ur-manager-section">
        <div className="container-width pd-30">
          <Grid container spacing={3} className="jc-sb">
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className=""
              order={{ sm: 2, lg: 1, md: 1, xs: 2 }}
            >
              <div className="header-text">
                Meet your dedicated Relationship Manager
              </div>

              <div className="mt-4 mb-4 ms-1">
                <div className="d-flex">
                  <div className="checked-icon-bg me-2 d-flex jc-center align-center">
                    <img src={checkedIcon} alt="checked" />
                  </div>
                  <span>
                    Get assistance from an exclusive Relationship Manager.
                  </span>
                </div>
                <div className="mt-3">
                  <div className="d-flex">
                    <div className="checked-icon-bg me-2 d-flex">
                      <img src={checkedIcon} alt="checked" />
                    </div>
                    <span>Get help in choosing your best loan option</span>
                  </div>
                </div>
              </div>
              <AppButton
                className="app-btn mt-2"
                text="Book a free consultation"
              />
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              sm={12}
              xs={12}
              className=""
              order={{ sm: 1, lg: 2, md: 1, xs: 1 }}
            >
              <div className="caller-img-view">
                {/* <img src={callerImage} className="w-100" alt="callerImage" /> */}
                <div className="caller-icon-bg">
                  <img src={phoneIcon} alt="caller-icon" />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    );
  };

  const offersCardView = () => {
    return (
      <section className="container-width pd-30">
        <div className="offers-card-view">
          <div className="top-half-curved"></div>
          <div className="bottom-half-curved"></div>
          <div className="exciting-offers-view d-flex align-center">
            <img src={giftBox} alt="gif" />
            <div className="ml-3 ">
              <div className="heading">A Wedding Gift Box for you</div>
              {/* <div className="heading">Exciting offers for you</div> */}
              <div className="mt-10">Curated benefits from 200+ brands</div>
            </div>
          </div>
          <Grid container spacing={1} mt={1}>
            <Grid item lg={3} md={6} sm={6} xs={6}>
              <img src={croma} alt="croma" className="w-100" />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={6}>
              <img src={tanishq} alt="tanishq" className="w-100" />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={6}>
              <img src={taj} alt="taj" className="w-100" />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={6}>
              <img src={fab} alt="fab" className="w-100" />
            </Grid>
          </Grid>
        </div>
      </section>
    );
  };

  // const whyWeddingLoansView = () => {
  //     return (
  //         <section className="container-width pd-30">
  //             <div className="heading">
  //                 Why Wedding Loan?
  //                 <div>from <span className="matrimony-link">matrimony.com </span>group</div>
  //             </div>
  //             <Grid container spacing={3} className="mt-3 align-center">
  //                 <Grid item lg={3} md={3} xs={12} sm={6}>
  //                     <div className="wedding-loans-card ">
  //                         <img src={paperLessIcon} alt="paperLessIcon" />
  //                         <div className="wedding-loans-text">100% Paperless Process</div>
  //                     </div>

  //                 </Grid>
  //                 <Grid item lg={3} md={3} xs={12} sm={6}>
  //                     <div className="wedding-loans-card">

  //                         <img src={docIcon} alt="docIcon" />
  //                         <div className="wedding-loans-text">Guaranteed data security</div>
  //                     </div>
  //                 </Grid>
  //                 <Grid item lg={3} md={3} xs={12} sm={6}>
  //                     <div className="wedding-loans-card">
  //                         <img src={bestDealsIcon} alt="bestDealsIcon" />
  //                         <div className="wedding-loans-text">Best deals & vouchers</div>
  //                     </div>
  //                 </Grid>
  //                 <Grid item lg={3} md={3} xs={12} sm={6}>
  //                     <div className="wedding-loans-card">
  //                         <img src={customerSupportIcon} alt="customerSupportIcon" />
  //                         <div className="wedding-loans-text">24/7 Customer Support</div>
  //                     </div>
  //                 </Grid>
  //             </Grid>
  //         </section>
  //     )
  // }

  const closeAllDropdown = () => {
    setToolDropdown(false);
    setHelpCentreDropdown(false);
  };

  const showDropdown = (e: any, key: any, value: any) => {
    closeAllDropdown();
    if (key === "toolDropdown") {
      setToolDropdown(!showToolDropdown);
    } else {
      setHelpCentreDropdown(!showHelpCentreDropdown);
    }
  };

  const handleStepChange = (step: number) => {
    setCurrentIndex(step);
  };

  const guidesSection = () => {
    return (
      <section className="container-width pd-30 mb-4" id="user-guide-section">
        <div className="d-flex jc-sb align-center">
          <div className="header-text">User Guides</div>
          {width >= 420 && (
            <a
              href="/blog"
              target="_blank"
              rel="noreferrer"
              className="see-all-text"
            >
              See all articles
              <img src={rightArrowIcon} alt="rightArrowIcon" />
            </a>
          )}
        </div>
        <AutoPlaySwipeableViews
          className="w-100 mt-3"
          // index={currentIndex}
          onChangeIndex={handleStepChange}
        >
          {(getCarouselItemsList() || []).map((item: any, index: any) => {
            return (
              <div key={index}>
                <Grid container spacing={3} className="p-2">
                  {(item || []).map((value: any, i: any) => {
                    if (value.imgSrc) {
                      return (
                        <Grid item lg={4} md={6} sm={12} xs={12} key={i}>
                          <div className="guide-card-view d-flex align-center">
                            <div>
                              <img src={value.imgSrc} alt="carousel-img" />
                            </div>
                            <div className="ml-3">
                              <div>{value.content}</div>
                              <div className="mt-10 ">
                                <a
                                  href={value.link}
                                  className="fraud-detect-link "
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Check now
                                </a>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </div>
            );
          })}
        </AutoPlaySwipeableViews>
        <MobileStepper
          steps={getCarouselItemsList().length}
          position="static"
          variant="dots"
          nextButton={<Button size="small"></Button>}
          backButton={<Button size="small"></Button>}
          activeStep={currentIndex}
        />
        {width <= 420 && (
          <div className="text-center mt-10">
            <a
              href="https://weddingloancom.blogspot.com/"
              target="_blank"
              rel="noreferrer"
              className="see-all-text text-center"
            >
              See all articles
            </a>
          </div>
        )}
      </section>
    );
  };

  // const customerSaysView = () => {
  //     return (
  //         <section className="customer-says-view">
  //             <div className="container-width pd-30">
  //                 <div className="header-text">What our customers say</div>
  //                 <Carousel
  //                     interval={2000}
  //                     className="w-100 mt-3"
  //                 >
  //                     <Carousel.Item>
  //                         <Row className="p-2 gy-3">
  //                             <Col lg={4}>
  //                                 <div className="customer-feedback-card-view">
  //                                     <img src={doubleQuotes} alt="doubleQuotes" />
  //                                     <div>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </div>
  //                                     <div className="fw-bold mt-2">Kiran</div>
  //                                     <div>Chennai</div>
  //                                 </div>
  //                             </Col>
  //                             <Col lg={4}>
  //                                 <div className="customer-feedback-card-view">
  //                                     <img src={doubleQuotes} alt="doubleQuotes" />
  //                                     <div>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </div>
  //                                     <div className="fw-bold mt-2">Akshay Kumar</div>
  //                                     <div>Chennai</div>
  //                                 </div>
  //                             </Col>
  //                             <Col lg={4}>
  //                                 <div className="customer-feedback-card-view">
  //                                     <img src={doubleQuotes} alt="doubleQuotes" />
  //                                     <div>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </div>
  //                                     <div className="fw-bold mt-2">Karthikeyan</div>
  //                                     <div>Chennai</div>
  //                                 </div>
  //                             </Col>
  //                         </Row>
  //                     </Carousel.Item>
  //                 </Carousel>
  //             </div>
  //         </section>
  //     )
  // }

  const customerFaq = () => {
    return (
      <section className="container-width pd-30">
        <div className="header-text">Customer FAQs</div>
        <Accordion className="mt-4">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {" "}
            What is a wedding loan?
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              A wedding loan is a financial credit facility designed to help you
              cover your wedding expenses. Depending on the end use requirements
              it could be a personal loan, an orverdraft facility, a revolving
              credit or other novel credit products designed by lenders to help
              you in meeting your wedding expenses. Typical usage of these funds
              include costs such as venue, catering, attire, jewellery, home
              setup, wedding gifts and wedding-related services. This type of
              loan can be utilized by any family member to support the event's
              financial needs.
            </Typography>
            <Typography mt={2}>
              A customer must be mindful not to use wedding loan to fund
              extravagant expenses, including but not limited to, expensive
              gifts, extravagant venues, lavish caterings. A wedding loan should
              be used only as a top up to meet the gap between your budget and
              actual expenses. This gap could be anywhere between 1 lac - 10
              lacs across India based on our pan India research.
            </Typography>
            <Typography mt={2}>
              Using the wedding loan services of Matrimony.com you will also be
              eligible for other services which can help you save on some of
              these expenses. For example, wedding loan customers will get
              access to Wedding Giftbox which provides discounts across 200+
              national brand partners.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {" "}
            What is the eligibility criteria for a wedding loan?
          </AccordionSummary>
          <AccordionDetails>
            To qualify for a wedding loan, you need a stable source of income,
            whether you are a salaried employee, self-employed entrepreneur, or
            a professional. Your eligibility may also depend on the company you
            work for, your credit history, your place of residence, and other
            criteria set by the lender.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {" "}
            Why should I share my PAN number?
          </AccordionSummary>
          <AccordionDetails>
            The PAN is used to evaluate your creditworthiness and financial
            history. This score indicates how reliable you are as a borrower,
            and a higher score generally improves the likelihood of lenders
            approving your loan application.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {" "}
            Can my fiancé be a co-applicant for a wedding loan?
          </AccordionSummary>
          <AccordionDetails>
            No, The co-applicant must be a family member, such as your spouse or
            parents. You will need to provide necessary documents to verify this
            relationship.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {" "}
            What is the minimum loan amount I can get?
          </AccordionSummary>
          <AccordionDetails>
            Yes, while the specific minimum loan amount can differ between
            lending institutions, most lenders generally set their minimum
            principal amount at Rs. 50,000.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            What is not a Wedding Loan?
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              A wedding loan is not a credit facility to fund extravagant
              wedding expenses , for example, booking a 5-star hotel venue,
              booking a destination wedding , buy a luxury car for gifting,
              distributing cash as gift or any other expenses which is not
              advisable to be done on a loan or credit facility.
            </Typography>
            <Typography mt={2}>
              A wedding loan is not a savings account or investment product
              intended for future wedding costs. It is a credit or loan facility
              that offers immediate funding for wedding expenses, rather than a
              method for accumulating funds over time.
            </Typography>
            <Typography mt={2}>
              A wedding loan is not a grant or financial gift. Unlike grants or
              gifts, which do not require repayment, a wedding loan is a form of
              credit that must be repaid over time with interest.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </section>
    );
  };

  const submitFn = (event: React.FormEvent<HTMLFormElement>) => {
    // const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (!validateMobileNumber(mobileNumber) && mobileNumber !== "") {
      return setValidation({
        isValid: false,
        message: ValidationErrorMessage.mobileValidationError,
      });
    }

    if (validateMobileNumber(mobileNumber) || mobileNumber === "") {
      navigate("/login", { state: mobileNumber });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 10) return;

    setValidation({ isValid: true, message: "" });

    if (/^\d{0,10}$/.test(e.target.value)) {
      setMobileNumber(e.target.value);
    }
  };

  const goToLogin = () => {
    navigate("/login");
  };

  const handleMenu = (e: any, key: number) => {
    e.stopPropagation();
    if (key === 1) {
      const userguidesection = document.getElementById("user-guide-section")!;
      userguidesection.scrollIntoView({
        behavior: "smooth",
      });
    } else if (key === 3) {
      const emiid = document.getElementById("emiSection")!;
      emiid.scrollIntoView({
        behavior: "smooth",
      });
    } else if (key === 2) {
      const creditScore = document.getElementById("creditScoreSection")!;
      creditScore.scrollIntoView({
        behavior: "smooth",
      });
    } else if (key === 5) {
      const contactus = document.getElementById("contact-us")!;
      contactus.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !/^\d$/.test(e.key) &&
      ![
        "Backspace",
        "Delete",
        "Tab",
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown",
      ].includes(e.key)
    ) {
      e.preventDefault();
    }
  };

  return (
    <>
      <header className="header-view ">
        {showSidebar && <div className="menu-overlay"></div>}
        <Container>
          <nav className="d-flex jc-sb align-center ">
            <div className="d-flex align-center">
              <div className="menu-bar d-none" onClick={() => openSidebar()}>
                <img src={menubar} alt="menubar" />
              </div>
              <div className="d-flex align-center">
                {width <= 768 ? <MobileLogo /> : <LogoIcon />}
                {/* <img src={Logo} alt="logo" /> */}
                {/* <img src={LogoIcon} alt="logo" width={220} height={38} /> */}
                {/* <span className="fw-600 fs-26">Logo</span> */}
              </div>
            </div>
            <div className="d-flex gap-3 align-center">
              <ul
                className={
                  showSidebar
                    ? "side-bar d-flex align-center"
                    : "d-flex gap-3 align-center"
                }
              >
                <div
                  className="text-end close-icon d-none"
                  onClick={() => closeSideBar()}
                >
                  x
                </div>
                <li
                  onClick={(e: any) => handleMenu(e, 1)}
                  className="cursor-pointer"
                >
                  User Guides
                </li>
                <li>
                  <div className="pos-rel">
                    <div
                      onClick={(e: any) =>
                        showDropdown(e, "toolDropdown", showToolDropdown)
                      }
                      id="dropdownselect"
                    >
                      <div className="cursor-pointer">
                        Tools
                        <span className="ml-1">
                          <img
                            src={downArrow}
                            id="downArrow"
                            alt="down-arrow"
                            className={
                              showToolDropdown
                                ? "down-arrow open"
                                : "down-arrow"
                            }
                          />
                        </span>
                      </div>
                      {showToolDropdown && (
                        <div className="dropdown-menu-placement">
                          <div
                            className="dropdown-item"
                            onClick={(e: any) => handleMenu(e, 2)}
                          >
                            Check credit score
                          </div>
                          <div className="divider"></div>
                          <div
                            className="dropdown-item"
                            onClick={(e: any) => handleMenu(e, 3)}
                          >
                            EMI Calculator
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="pos-rel">
                    <div
                      // onClick={(e: any) => showDropdown(e, "helpCentreDropdown", showHelpCentreDropdown)}
                      id="dropdownselect"
                    >
                      <div
                        className="cursor-pointer"
                        onClick={(e: any) => handleMenu(e, 5)}
                      >
                        Contact Us
                        {/* <span className="ml-1" >
                                                    <img src={downArrow} alt="down-arrow" id="help-centre-drop" className={showHelpCentreDropdown ? "down-arrow open" : "down-arrow"} />
                                                </span> */}
                      </div>
                      {/* {showHelpCentreDropdown &&
                                                <div className="dropdown-menu-placement">
                                                    <div className="dropdown-item" onClick={(e: any) => handleMenu(e, 4)}>Email</div>
                                                    <div className="divider"></div>
                                                    <div className="dropdown-item" onClick={(e: any) => handleMenu(e, 5)}>Contact us</div>
                                                    <div className="divider"></div>
                                                    <div className="dropdown-item" onClick={(e: any) => handleMenu(e, 6)}>Raise a ticket</div>
                                                </div>} */}
                    </div>
                  </div>
                </li>
              </ul>
              <AppButton
                className="login-btn"
                onClick={() => goToLogin()}
                text="Login"
              />
            </div>
          </nav>
          <div className="banner-content-view mt-5 d-flex jc-sb">
            <div className="w-50 pb-4 mb-3">
              <div className="banner-content mt-4">
                <div>
                  Plan Your Perfect Wedding with our flexible loan options!{" "}
                </div>
              </div>
              <form noValidate onSubmit={submitFn}>
                <div className="mobile-number-input-view gap-3 d-flex mt-4">
                  <FormControl className="w-50">
                    <TextField
                      required
                      className="mobile-input"
                      type="number"
                      placeholder="Enter mobile number"
                      name="mobileNumber"
                      onChange={handleChange}
                      value={mobileNumber}
                      InputProps={{ inputProps: { min: 0, max: 10 } }}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                        handleKeyDown(e)
                      }
                    />
                    <FormHelperText
                      // error={Boolean(mobileNumber !== "" && validateMobileNumber(mobileNumber) === false)}
                      sx={{
                        color: "#FF3333 !important",
                        margin: "6px 0px 0px 0px !important",
                      }}
                    >
                      <span>
                        {!validation.isValid
                          ? ValidationErrorMessage.mobileValidationError
                          : ""}
                      </span>
                    </FormHelperText>
                    {/* <Form.Control.Feedback type="invalid">
                                            {ValidationErrorMessage.mobileValidationError}
                                        </Form.Control.Feedback> */}
                  </FormControl>
                  <div className="w-50">
                    <AppButton
                      type="submit"
                      className="app-btn w-100"
                      text="Get started"
                    />
                    {/* <Button className="app-btn w-100 book-free-consult-btn" type="submit">Get started</Button> */}
                  </div>
                </div>
              </form>
              <div className="description-text mt-3">
                <span>Call Us at</span>{" "}
                <span className="fw-bold">+91-6369033555</span>
              </div>
            </div>
            <div className="banner-img-view">
              <div>
                <img src={bannerImg} alt="bannerImg" />
              </div>
              <div className="banner-card-view credit-icon-card-view">
                <div className="d-flex">
                  <div className="free-credit-card">
                    <img src={creditReportIcon} alt="free-credit-img" />
                  </div>
                  <div className="fw-bold ms-2 fs-12">
                    <div>Get FREE Credit score & Report</div>
                  </div>
                </div>
              </div>

              <div className="banner-card-view exclusive-offers-card-view">
                <div className="d-flex">
                  <div className="exclusive-offers-card">
                    <img src={exclusiveOffers} alt="offers-img" />
                  </div>
                  <div className="fw-bold ms-2 fs-12">
                    <div>Curated brand benefits</div>
                  </div>
                </div>
              </div>
              <div className="banner-card-view dedicated-manager-card-view">
                <div className="d-flex">
                  <div className="relationship-manager-card">
                    <img src={relationshipManagerIcon} alt="offers-img" />
                  </div>
                  <div className="fw-bold ms-2 fs-12">
                    <div>Dedicated Relationship Manager</div>
                  </div>
                </div>
              </div>
              <div className="chat-icon-card-view">
                <div className="chat-icon-card">
                  <img src={chatIcon} alt="chatIcon" className="chat-icon" />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </header>

      {/**/}
      <section className="container-width pd-30">
        <div className="header-text">
          Why Wedding Loan?
          <div className="fs-20">
            From <span className="matrimony-link">matrimony.com </span>group
          </div>
        </div>
        <Grid container spacing={3} className="mt-2">
          <Grid item lg={4} md={4} sm={6} className="w-100">
            <div className="details-card-view dedicated-relationship-card-border">
              <div className="d-flex jc-sb">
                <div className="details-title">
                  Dedicated Relationship Manager
                </div>
                <div>
                  <img src={RelationshipIcon} alt="RelationshipIcon" />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={6} className="w-100">
            <div className="details-card-view offers-card-border">
              <div className="d-flex jc-sb">
                <div className="details-title">
                  Choose the best loan for your needs
                </div>
                <div>
                  <img src={OffersIcon} alt="OffersIcon" />
                </div>
              </div>
            </div>
          </Grid>

          <Grid item lg={4} md={4} sm={6} className="w-100">
            <div className="details-card-view save-big-card-border">
              <div className="d-flex jc-sb">
                <div className="details-title">
                  Save big with our curated brand benefits
                </div>
                <div>
                  <img src={SaveBigIcon} alt="SaveBigIcon" />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>

      {/*Multi loans*/}
      <section className="bg-blue pd-30">
        <div className="container-width">
          <div className="header-text">Collaboration with leading lenders </div>
          <div className="mt-3">
            <Grid container spacing={1} className="text-center jc-sb">
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <div className="lender-logo-view tata-logo-bg">
                  <img src={tata_capital} alt="tata_capital" className="" />
                </div>
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <div className="lender-logo-view">
                  <img src={IDFCLogo} alt="logo" className="lender-img" />
                  <img
                    src={firstMoneyLogo}
                    alt="first-money"
                    className="lender-img"
                  />
                </div>
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <div className="lender-logo-view lt-bg">
                  <Box
                    component="a"
                    href="https://www.ltfs.com/our-products/personal-loans"
                    target="_blank"
                  >
                    <img src={LTlogo} />
                  </Box>
                </div>
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <div className="lender-logo-view">
                  <img src={tvs_logo} alt="tvs_logo" />
                </div>
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <div className="lender-logo-view">
                  <Box
                    component="a"
                    href="https://bhanixfinance.com/ourpartners.html"
                    target="_blank"
                  >
                    <img src={bhanixLogo} />
                  </Box>
                </div>
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <div className="lender-logo-view">
                  <img src={MoneyView_logo} alt="MoneyView_logo" />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>

      {/*loan benefits*/}
      {loanBenefitsView()}

      {/*credit score section*/}
      {creditsSection()}

      {/*meet ur manager*/}
      {/* {meetmanagerSection()} */}

      {/*offers card section*/}
      {offersCardView()}

      {/* <section className="container-width pd-30">
                <Grid container spacing={3} className="mt-3 align-center">
                    <Grid item lg={3} md={3} xs={12} sm={6}>
                        <div className="wedding-loans-card ">
                            <img src={paperLessIcon} alt="paperLessIcon" />
                            <div className="wedding-loans-text">100% Paperless Process</div>
                        </div>

                    </Grid>
                    <Grid item lg={3} md={3} xs={12} sm={6}>
                        <div className="wedding-loans-card">

                            <img src={docIcon} alt="docIcon" />
                            <div className="wedding-loans-text">Guaranteed data security</div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} xs={12} sm={6}>
                        <div className="wedding-loans-card">
                            <img src={bestDealsIcon} alt="bestDealsIcon" />
                            <div className="wedding-loans-text">Best deals & vouchers</div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} xs={12} sm={6}>
                        <div className="wedding-loans-card">
                            <img src={customerSupportIcon} alt="customerSupportIcon" />
                            <div className="wedding-loans-text">24/7 Customer Support</div>
                        </div>
                    </Grid>
                </Grid>
            </section> /*}

            {/*why wedding loans section*/}

      {/*guides section*/}
      {guidesSection()}

      {/*customer says*/}
      {/* {customerSaysView()} */}
      <section
        className="emi-section-view container-width pd-30"
        id="emiSection"
      >
        <EmiCardView />
      </section>

      {customerFaq()}

      {/*footer view*/}
      <footer className="app-bg text-white" id="contact-us">
        <div className="contact-us-view">
          For further help, please contact us -
          <a className="fw-bold" target="_blank" href="mailto:help@weddingloan.com"> help@weddingloan.com</a>
        </div>
        <div className="container-width pd-30">
          <Grid container spacing={3} className="">
            <Grid item lg={3} md={12} sm={12} xs={12}>
              <div
                className={
                  medium || smallScreen || matches ? "text-center" : ""
                }
              >
                <Box sx={{ path: { fill: "white" } }}>
                  <LogoIcon />
                </Box>
              </div>
            </Grid>
            <Grid item lg={7} md={12} sm={12} xs={12} className="text-center">
              <Grid
                container
                spacing={2}
                className={
                  matches ? "align-center jc-evenly" : "align-center jc-center"
                }
              >
                <Grid item lg={4} md={2} sm={4}>
                  <div className={"bord-right"}>
                    <div className="mr-10">About us</div>
                    {/* <span className="bord-right"></span> */}
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={4}>
                  <div className={"bord-right"}>
                    <Box
                      component="a"
                      target="_blank"
                      href="/terms-conditions"
                      sx={{
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "white",
                        marginRight: "10px",
                      }}
                    >
                      Terms & conditions
                    </Box>
                    {/* <span className="bord-right"></span> */}
                  </div>
                </Grid>
                <Grid item lg={4} md={3} sm={4}>
                  <div>
                    <Box
                      component="a"
                      target="_blank"
                      href="/privacy-policy"
                      sx={{
                        cursor: "pointer",
                        marginRight: "10px",
                        textDecoration: "none",
                        color: "white",
                      }}
                    >
                      Privacy policy
                    </Box>
                  </div>
                </Grid>
                <Grid item lg={5} md={6} sm={6}>
                  <div className={"bord-right"}>
                    <Box
                      component="a"
                      target="_blank"
                      href="https://rbidocs.rbi.org.in/rdocs/notification/PDFs/GUIDELINESDIGITALLENDINGD5C35A71D8124A0E92AEB940A7D25BB3.PDF"
                      sx={{
                        cursor: "pointer",
                        textDecoration: "none",
                        marginRight: "10px",
                        color: "white",
                      }}
                    >
                      Digital Lending Guidelines
                    </Box>
                    {/* <span className="bord-right"></span> */}
                  </div>
                </Grid>
                <Grid item lg={3} md={6} sm={4}>
                  <div className={"bord-right"}>
                    <Box
                      component="a"
                      target="_blank"
                      href="https://sachet.rbi.org.in/"
                      sx={{
                        cursor: "pointer",
                        textDecoration: "none",
                        marginRight: "10px",
                        color: "white",
                      }}
                    >
                      Sachet Link
                    </Box>
                  </div>
                </Grid>

                <Grid item lg={3} md={6} sm={4}>
                  <Box
                    component="a"
                    target="_blank"
                    href="/product-details"
                    sx={{
                      cursor: "pointer",
                      textDecoration: "none",
                      marginRight: "10px",
                      color: "white",
                    }}
                  >
                    Product Details
                  </Box>
                </Grid>
                <Grid item lg={5} md={6} sm={4}>
                  <Box
                    component="a"
                    target="_blank"
                    href="/grievance-officer-details"
                    sx={{
                      cursor: "pointer",
                      textDecoration: "none",
                      marginRight: "10px",
                      color: "white",
                      textAlign:'start'
                    }}
                  >
                    Grievance Officer Details
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={2} md={12} sm={12} xs={12}>
              <div className="d-flex align-center jc-center gap-10">
                <img src={instaIcon} alt="instaIcon" />
                <img src={facebookIcon} alt="facebookIcon" />
                <img src={utubeIcon} alt="utubeIcon" />
              </div>
            </Grid>
          </Grid>

          <div className="footer-line mt-15"></div>
          <div className="text-center mt-3">
            © Copyright {date.getFullYear()} WeddingLoan (Matrimony.com Group) -
            All Rights Reserved
          </div>
        </div>
      </footer>
    </>
  );
};

export default LandingPage;
