import { gql } from '@apollo/client';


export const ApplyLTLOAN = gql`mutation ApplyLTLoan($input: ApplyLTLoanInput) {
    applyLTLoan(input: $input) {
      applyLTLoanErrors {
        message
      }
      status
      message
      data {
        id
        appRef
        applicantName
        breMaxEligibility
        breMaxTenure
        breMinTenure
        createddate
        interestRate
        lastmodifieddate
        leadId
        mobile
        product
        sourcingChannel
        stage
        subStage
        tenure
      }
      url
    }
  }`